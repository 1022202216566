@use "../../scss/variables.module.scss" as *;

.typical-projects h2 {
  color: $sch-orange;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
}

.typical-projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  margin: 0px auto 20px;
  padding: 20px;
}

.typical-projects_header {
  text-transform: uppercase;
  font-size: 1em;
  margin: 0px 0px 10px 0px;
  padding: 0;
}

.typical-projects_list {
  background-color: $sch-lightgray;
  border-radius: 8px;
  list-style: none;
  font-size: 1.1em;
  line-height: 1.75em;
  color: $sch-midgray;
  padding: 20px 40px;
  columns: 2;
}

@media all and (max-width: $tablet-breakpoint) {
  .typical-projects_list {
    columns: 2;
    text-align: left;
    font-size: 0.7em;
    padding: 10px 20px;
  }
}

@media all and (max-width: $phone-breakpoint) {
  .typical-projects_list {
    columns: 1;
    text-align: center;
    font-size: 1em;
  }
}
