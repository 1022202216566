@use "../../scss/variables.module.scss" as *;

.contact-us {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  color: rgb(255, 255, 255);
  padding: 30px;
  background-color: $sch-gray;
}

@media all and (max-width: $tablet-breakpoint) {
  .contact-us {
    flex-direction: column;
  }
}

.contact-us_left {
  // width: 300px;
  width: 32%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9em;
  line-height: 1.4em;
}

@media all and (max-width: $tablet-breakpoint) {
  .contact-us_left {
    width: 100%;
  }
}

.contact-us_right {
  width: 65%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media all and (max-width: $tablet-breakpoint) {
  .contact-us_right {
    width: 100%;
    flex-direction: column;
  }
}

.contact-us_title {
  font-weight: 600;
  font-size: 0.9em;
  margin: 0px 0px 8px 0px;
}

.request-form {
  // width: 600px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media all and (max-width: $tablet-breakpoint) {
  .request-form {
    margin: 10px 0px 0px 0px;
    flex-direction: column;
  }
}

.request-form_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.request-form_input,
.request-form_textarea {
  width: 94%;
  font-family: "Work Sans";
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.5em;
  padding: 8px 12px;
  border-radius: 8px;
  color: $sch-gray;
  background: $sch-white;
  border: 1px solid $sch-midgray;
  outline: none;
  // box-shadow: none;
  margin: 5px 0px;
}

.request-form_input:focus,
.request-form_input:hover,
.request-form_textarea:focus,
.request-form_textarea:hover {
  border-color: $sch-orange;
  // outline: orange;
}

@media all and (max-width: $tablet-breakpoint) {
  .request-form_input,
  .request-form_textarea {
    width: 100%;
  }
}

.request-form_textarea {
  resize: none;
  height: 140px;
}

.submit-btn {
  background-color: $sch-orange;
  color: $sch-white;
  word-wrap: break-word;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 5px 16px;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid;
  border-radius: 6px;
  appearance: none;
  transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: color, background-color, box-shadow, border-color;
  border-color: rgba(240, 246, 252, 0.1);
  box-shadow: 0 0 transparent, 0 0 transparent;
  text-align: center !important;
}

.submit-btn:hover {
  transition-duration: 80ms;
  background-color: $sch-orange;
}

.submit-btn:active {
  transition: none;
}

// .request-form_input:hover {
//   border-color: $sch-lightgray;
// }

// .request-form_input:focus {
//   border-color: $sch-white;
//   box-shadow: 0 0 0 3px $sch-black;
// }

// // firefox
// .request-form_input:focus-visible {
//   outline: 0;
// }

.thank-you {
  background-color: $sch-midgray;
  color: $sch-white;
  border: 1px dashed $sch-midgray;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0px auto;
}

@media all and (min-width: $tablet-breakpoint) {
  .thank-you {
    margin: auto;
    padding: 40px;
  }
}

.thank-you_title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1em;
  margin: 0px 0px 8px 0px;
  text-align: center;
}

@media all and (min-width: $tablet-breakpoint) {
  .thank-you_title {
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

.thank-you p {
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.3em;
  text-align: center;
}

@media all and (min-width: $tablet-breakpoint) {
  .thank-you p {
    font-size: 0.9em;
    line-height: 1.2em;
  }
}
