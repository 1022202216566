$sch-orange: rgba(255, 102, 0, 1);
$sch-gray: rgba(51, 51, 51, 1);
$sch-lightgray: rgba(200, 200, 200, 1);
$sch-midgray: rgba(102, 102, 102, 1);
$sch-white: rgba(255, 255, 255, 1);
$sch-black: rgba(0, 0, 0, 1);
// $test-border: 1px solid pink;
$test-border: none;

$screen-breakpoint: 1000px;
$tablet-breakpoint: 670px;
$phone-breakpoint: 450px;

:export {
  phonesize: $phone-breakpoint;
  screensize: $screen-breakpoint;
  tabletsize: $tablet-breakpoint;
}
