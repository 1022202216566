@use "../../scss/variables.module.scss" as *;

.project-sample {
  border: 1px solid $sch-lightgray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  margin: 5px 5px 0px 0px;
}

.project-sample_img {
  margin: 8px 0px;
}

.project-sample_title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  margin: 0;
  color: $sch-gray;
}

.project-sample_list {
  list-style: none;
  line-height: 1.5em;
  font-size: 0.8em;
  margin: 0;
  padding: 0;
}

@media all and (max-width: $screen-breakpoint) {
  .project-sample_list {
    margin: 6px 0px 0px 10px;
  }
}

@media all and (max-width: $phone-breakpoint) {
  .project-sample_list {
    margin: 0;
  }
}
