@use "../../scss/variables.module.scss" as *;

.logo-type {
  margin: 20px;
  padding: 25px;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.25em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  --orange: 255, 102, 0;
  background-color: rgba(var(--orange), 100);
  // max-width: 150px;
  position: absolute;
  z-index: 1;
}

@media all and (max-width: $screen-breakpoint) {
  .logo-type {
    margin: 20px;
    padding: 10px;
    font-size: 1.1em;
    line-height: 1.2em;
  }
}
