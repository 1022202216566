.app {
  background-color: rgb(100, 100, 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./images/bg.svg");
}

.app-header,
.app-body,
.app-footer {
  background-color: rgba(255, 255, 255);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1024px;
}

.app-header {
  border: 0;
  /* border-bottom: 1px solid rgb(210, 210, 210);
  position: relative; */
}

.app-body {
  padding: 10px;
  border: 0;
  margin: 0;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.app-footer {
  background-color: rgb(0, 0, 0);
}
