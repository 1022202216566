@use "../../scss/variables.module.scss" as *;

.header-img {
  position: relative;
  max-width: 1024px;
  overflow: hidden;
}

.header-img_img {
  height: auto;
  width: 100%;
}

@media all and (max-width: $phone-breakpoint) {
  .header-img {
    max-width: 100vw;
  }
  .header-img_img {
    height: 320px;
    width: auto;
  }
}

.header-img_overlay {
  position: absolute;
  color: $sch-white;
  background-color: $sch-gray;
  opacity: 0.85;
  background-blend-mode: darken;
  bottom: 30px;
  left: 20px;
  padding: 10px;
  max-width: 280px;
}

.header-img_overlay-title {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  line-height: 2em;
}

.header-img_overlay-desc {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.25em;
}
