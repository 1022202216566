@use "../../scss/variables.module.scss" as *;

.body-copy {
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.5em;
  color: $sch-gray;
  margin: 10px 0px 20px 0px;
  padding: 20px;
}

.body-copy p {
  margin: 0px 0px 12px 0px;
}

.body-copy p:last-of-type {
  margin: 0px 0px 0px 0px;
}
