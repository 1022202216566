@use "../../scss/variables.module.scss" as *;

.project-gallery h2 {
  color: $sch-orange;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  margin: 0;
}

@media all and (max-width: $tablet-breakpoint) {
  .project-gallery h2 {
    margin: 0px 0px 10px 0px;
  }
}

.project-gallery {
  padding: 20px;
}

.project-gallery_thumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: auto;
}
